<script setup>
const {locales, locale} = useI18n()
const switchLocalePath = useSwitchLocalePath()
</script>

<template>
  <div class="menu-lang" ref="menuLang">
    <ul class="menu-list">
      <li
        v-for="(item, index) in locales"
        v-if="locale"
        :key="index"
        class="menu-item"
        :class="{active: locale == item.code}">
        <NuxtLink
          :to="switchLocalePath(item.code)"
          external
          class="menu-nolink sensible">
          {{ item.code }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.menu-lang {
  .menu-list {
    @include list-reset;
    display: flex;
  }

  .menu-item {
    flex: 1;
    flex-shrink: 0;
    margin-right: pxrem(20);

    &.active {
      .menu-nolink {
        opacity: 1;

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .menu-nolink {
    @include label($color-white);
    opacity: 0.2;
    padding-bottom: 2px;
    padding-top: 2px;
    position: relative;
    text-decoration: none;
    transition: opacity $transition-default;
    user-select: none;

    &:hover {
      opacity: 1;
    }

    &::after {
      background-color: $color-white;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity $transition-default;
      width: 90%;
    }
  }
}
</style>
