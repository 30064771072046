<script setup>
import menuSecondaryJSON from 'assets/data/menu-secondary.json'
import {useLocalePath} from 'vue-i18n-routing'
const {locale} = useI18n()
const localePath = useLocalePath()
</script>

<template>
    <nav class="menu-secondary">
        <ul class="menu-list">
            <li
                v-for="item in menuSecondaryJSON[locale].menu_items"
                :key="item.path"
                class="menu-item">
                <NuxtLink
                    v-if="!item.target"
                    :to="localePath(item.path)"
                    class="menu-link sensible"
                >{{ item.label }}</NuxtLink
                >
                <NuxtLink
                    v-else
                    :to="item.path"
                    :target="item.target"
                    class="menu-link sensible"
                >{{ item.label }}</NuxtLink
                >
            </li>
        </ul>
    </nav>
</template>

<style lang="scss" scoped>
.menu-secondary {
    .menu-list {
        @include list-reset;

        @include mq(xs) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        @include mq(md) {
            display: flex;
        }
    }

    .menu-item {
        flex-shrink: 0;
        margin-right: pxrem(20);

        &.active {
            .menu-link {
                opacity: 1;

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .menu-link {
        @include paragraph($color-white);

        padding-bottom: 2px;
        position: relative;
        text-decoration: none;
        transition: opacity $transition-default;
        user-select: none;

        @include mq(md) {
            opacity: 0.2;

            &:hover {
                opacity: 1;
            }
        }

        &::after {
            background-color: $color-white;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            opacity: 0;
            position: absolute;
            transition: opacity $transition-default;
            width: 90%;
        }
    }
}
</style>
